import {
  IonButton,
  IonCard,
  IonContent,
  IonPage,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { appData } from "../data/AppData";
import AccessReward from "../components/AccessReward";
import "./Ownership.css";
import CloseButton from "../components/CloseButton";
import { Link } from "react-router-dom";

const Ownership: React.FC = () => {
  const ownership = appData.find((item) => item.section == "Ownership");
  const history = useHistory();
  console.log(ownership);
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="sticky-header">
          <CloseButton className="close-button" myHistory={history} />
        </div>
        <img src={ownership?.detailImage} className="main-image" />
        <h5 className="ownership-detail-text">{ownership?.detailText}</h5>
        {ownership?.ownershipItems?.map((item) => (
          <IonCard key={item.itemNumber} mode="ios">
            <img src={item.icon} />
            <h6 className="ownership-title">{item.title}</h6>
            <p>{item.text}</p>
          </IonCard>
        ))}
        <AccessReward />
        <p style={{ paddingTop: "45px" }}>
          <Link to="disclaimers" className="disclaimers-link">
            Disclaimers
          </Link>
        </p>
      </IonContent>
    </IonPage>
  );
};

export default Ownership;
