import { IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import { appData } from "../data/AppData";
import CloseButton from "../components/CloseButton";
import { Link } from "react-router-dom";
import "./Meditation.css";

const calmImage = require("../assets/calm-icon.png");

const Meditation: React.FC = () => {
  const meditation = appData.find((item) => item.section === "Meditation");
  let history = useHistory();

  const openLink = () => {
    const url = "https://www.calm.com/resources/meditation";
    window.open(url, "_system");
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="sticky-header">
          <CloseButton className="close-button" myHistory={history} />
        </div>
        <img src={meditation?.detailImage} className="main-image" />
        <img src={calmImage} className="calm-image" />
        <h5 className="title-2">{meditation?.title}</h5>
        <button onClick={openLink}>Open Link</button>
        <p className="text">{meditation?.text}</p>
        <br/>
        <p className="text">{meditation?.text_2}</p>
        <p style={{ paddingTop: "45px" }}>
          <Link to="disclaimers" className="disclaimers-link">
            Disclaimers
          </Link>
        </p>
      </IonContent>
    </IonPage>
  );
};

export default Meditation;
