import { IonCard, IonContent, IonPage } from '@ionic/react';
import { Link, useHistory } from 'react-router-dom';
import AccessDetail from '../components/AccessDetail';
import CloseButton from '../components/CloseButton';
import { appData } from '../data/AppData';
import "./Access.css";

const Access: React.FC = () => {
  const access = appData.find(item => (item.section === "Access"));
  const history = useHistory();

  const openLink = () => {
    const url = "https://www.lincoln.com/support/lincoln-way-app/lincoln-access-rewards/overview/?gnav=header-owner-lnaccess";
    window.open(url, '_system');
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className='sticky-header'>
          <CloseButton className="close-button" myHistory={history} />
        </div>
        <img src={access?.img} className="main-image" />

        <p className='access-text'>Unlock more benefits the more you engage with Lincoln. Earn points with vehicle purchase, service appointments and more*</p>
        <p className='access-text'>A sample of the Lincoln Access Rewards available:</p>

        {access?.detailItems?.map(item =>
          <AccessDetail key={item.itemNumber} item={item} />
        )}
      <IonCard className="access-card-link">
        <h5>And so much more...</h5>
        <p className='access-text'>That was just a sample of the Lincoln Access Rewards. Learn about the full collection we offer.</p>
        <Link to='#' className='learn-more' onClick={openLink}>Learn More</Link>
      </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Access;
