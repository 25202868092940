import {
  IonButton,
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useHistory, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import CloseButton from "../components/CloseButton";
import Feature from "../components/Feature";
import { appData } from "../data/AppData";
import "./Features.css";

import interiorDetail from "../assets/interior-detail.png";
import adaptiveCruise from "../assets/adaptive-cruise-detail.png";

const Features: React.FC = () => {
  const features = appData.find((item) => item.section === "Features");
  const cookies = new Cookies();
  const vehicle = cookies.get("vehicle");
  console.log(vehicle);

  const history = useHistory();
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="sticky-header">
          <CloseButton className="close-button" myHistory={history} />
        </div>
        <img
          src={vehicle === "navigator" ? interiorDetail : adaptiveCruise}
          className="main-image"
        />
        {features?.featureSectionItems
          ?.filter((item) => item.tags.includes(vehicle))
          .map((item) => (
            <Feature key={item.itemNumber} item={item} />
          ))}
        <p style={{ paddingTop: "45px" }}>
          <Link to="disclaimers" className="disclaimers-link">
            Disclaimers
          </Link>
        </p>
      </IonContent>
    </IonPage>
  );
};

export default Features;
