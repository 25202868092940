import Cookies from "universal-cookie";
import { IonContent, IonPage, IonButton, IonCard } from "@ionic/react";
import { isPlatform } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { appData } from "../data/AppData";
import moment from "moment";
import { Link } from "react-router-dom";
import CloseButton from "../components/CloseButton";
import "./Rewards.css";
import AccessReward from "../components/AccessReward";

const Rewards: React.FC = () => {
  const history = useHistory();
  const cookies = new Cookies();
  const rewards = appData.find((item) => item.section === "Rewards");
  const reward = rewards?.rewardSectionItems?.find(
    (item) => item.rewardId === cookies.get("reward")
  );

  const rewardDate = () => {
    const dateString = cookies.get("rewardDateString");
    const date = moment(dateString).format("dddd, MMMM Do, h:mm a");
    if (date === "Invalid date") return null;
    return date;
  };

  const goToLocation = (item: any) => {
    const encodedAddress = encodeURI(
      `${item.locationName} ${item.locationAddress}`
    );

    if (isPlatform("ios")) {
      const url = `https://maps.apple.com/?daddr=${encodedAddress}`;
      window.open(url, "_system");
    } else {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
      window.open(url, "_system");
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="sticky-header">
          <CloseButton className="close-button" myHistory={history} />
        </div>
        <img src={reward?.rewardImage} />

        <h5>{reward?.title}</h5>
        <p>{reward?.intro}</p>
        <hr className="line-break"></hr>
        <p className="location">{reward?.locationName}</p>
        <p className="reward-text">{reward?.text}</p>
        <p>{reward?.locationAddress}</p>

        {reward?.rewardId !== "Nr" ? (
          <>
            {rewardDate() && <p className="date">{rewardDate()}</p>}
            <IonButton
              shape="round"
              fill="outline"
              color="dark"
              onClick={() => goToLocation(reward)}
            >
              View Map
            </IonButton>
            <AccessReward />
          </>
        ) : (
          <>
            <IonCard className="access-rewards-section-1">
              <IonButton
                shape="round"
                color="dark"
                fill="outline"
                onClick={() => history.push("access")}
              >
                Learn More
              </IonButton>
            </IonCard>
          </>
        )}

        <p style={{ paddingTop: "45px" }}>
          <Link to="disclaimers" className="disclaimers-link">
            Disclaimers
          </Link>
        </p>
      </IonContent>
    </IonPage>
  );
};

export default Rewards;
