import Cookies from "universal-cookie";
const cookies = new Cookies();
const vehicle = cookies.get("vehicle");

export const appData = [
  {
    img: require("../assets/access.png"),
    detailImage: require("../assets/access.png"),
    mainTitle: "Lincoln Access Rewards",
    secondaryTitle: "NONE",
    section: "Access",
    detailText:
      "Enjoy a range of services that give you back time and provide peace of mind.",
    detailItems: [
      {
        itemNumber: 1,
        mainTitle: "PURSUE YOUR PASSIONS",
        subItems: [
          {
            itemNumber: 1,
            title: "Culinary Experience",
            text: "Unique dining experience personally customized to your tastes.",
            image: require("../assets/access/passions-culinary.png"),
          },
          {
            itemNumber: 2,
            title: "Cultural Experience",
            text: "Get an insider’s view of your city or a travel destination, customized to your interests.",
            image: require("../assets/access/passions-cultural.png"),
          },
          {
            itemNumber: 3,
            title: "Entertainment Experience",
            text: "This personalized entertainment experience hits all the right notes.",
            image: require("../assets/access/passions-entertainment.png"),
          },
          {
            itemNumber: 4,
            title: "Wellness Experience",
            text: "Select a customized wellness experience designed to revitalize your mind and body.",
            image: require("../assets/access/passions-wellness.png"),
          },
        ],
      },
      {
        itemNumber: 2,
        mainTitle: "FOR YOUR VEHICLE",
        subItems: [
          {
            itemNumber: 1,
            title: "Detailing",
            text: "Keep your vehicle in pristine condition with detailing by the people who know your Lincoln best.",
            image: require("../assets/access/vehicle-detailing.png"),
          },
          {
            itemNumber: 2,
            title: "Services and Accessories",
            text: "Use points for future service or accessories.",
            image: require("../assets/access/vehicle-accessories.png"),
          },
        ],
      },
      {
        itemNumber: 3,
        mainTitle: "HEALTH & WELLNESS",
        subItems: [
          {
            itemNumber: 1,
            title: "Calm Subscription",
            text: "Elevate your wellness journey with the #1 app for Sleep, Meditation and Relaxation™.",
            image: require("../assets/access/health-calm.png"),
          },
          {
            itemNumber: 2,
            title: "Hyperice Core Meditation Guide & Premium Yoga Mat",
            text: "Ideal for anyone trying to begin or maintain a consistent, effective meditation practice.",
            image: require("../assets/access/health-2.png"),
          },
        ],
      },
      {
        itemNumber: 4,
        mainTitle: "TRAVEL",
        subItems: [
          {
            itemNumber: 1,
            title: "Getaway House Experience",
            text: "Simple escapes to nature that can restore the balance in your life.",
            image: require("../assets/access/travel-getaway.png"),
          },
          {
            itemNumber: 2,
            title: "2-Night Stay at SLS Baha Mar",
            text: "Sample the finest of the Bahamas at this luxury Nassau resort, with a $200 dining credit included.",
            image: require("../assets/access/travel-sls.png"),
          },
          {
            itemNumber: 3,
            title: "Winter Bed & Breakfast Stay at Stein Eriksen Deer Valley",
            text: "Simple escapes to nature that can restore the balance in your life.",
            image: require("../assets/access/travel-winter.png"),
          },
          {
            itemNumber: 4,
            title: "SLS South Beach Miami Hotel Experience",
            text: "Spend a night at SLS South Beach Miami, a boutique hotel offering redefined luxury and undeniable style.",
            image: require("../assets/access/travel-sls-south-beach.png"),
          },
        ],
      },
      {
        itemNumber: 5,
        mainTitle: "CULINARY",
        subItems: [
          {
            itemNumber: 1,
            title: "Culinary Collection Gift Card",
            text: "Allow us to treat you to an exceptional meal.",
            image: require("../assets/access/culinary-gift-card.png"),
          },
          {
            itemNumber: 2,
            title: "2-Night Stay at SLS Baha Mar",
            text: "Enjoy a customized culinary experience with a private chef in the comfort of your own home.",
            image: require("../assets/access/culinary-stay.png"),
          },
        ],
      },
      {
        itemNumber: 6,
        mainTitle: "LINCOLN MERCHANDISE",
        subItems: [
          {
            itemNumber: 1,
            title: "Stitch Golf Shoe Bag",
            text: "Your shoes never had it so good.",
            image: require("../assets/access/merch-backpack.png"),
          },
          {
            itemNumber: 2,
            title: "Lincoln Pitchfix Deluxe Gift Set",
            text: "Show off your love of Lincoln out on the course with this artful golf tool set.",
            image: require("../assets/access/merch-pitch.png"),
          },
        ],
      },
    ],
  },
  {
    img:
      vehicle === "navigator"
        ? require("../assets/interior-lincoln.png")
        : require("../assets/adaptive-cruise-cover.png"),
    mainTitle: "Elevate Your Ride",
    secondaryTitle: "Learn more about key features.",
    section: "Features",
    featureSectionItems: [
      {
        itemNumber: 1,
        tags: ["aviator", "nautilus", "corsair"],
        cover: require("../assets/cover-adaptivecruisecontrol.png"),
        video: require("../assets/adaptivecruisecontrol.mp4"),
        title: "Adaptive Cruise Control",
        text: "Adaptive Cruise Control (ACC) lets you set a cruising speed and distance from the vehicle ahead of you. When it slows down, you automatically do too. When traffic picks back up, you resume your preset speed and distance.",
      },
      {
        itemNumber: 2,
        tags: ["corsair"],
        cover: require("../assets/cover-activeparkasist.png"),
        video: require("../assets/activeparkasist.mp4"),
        title: "Active Park Assist",
        text: "Take the guesswork out of determining if your vehicle can be parked in the desired parallel parking space",
      },
      {
        itemNumber: 3,
        tags: ["aviator", "navigator", "nautilus"],
        cover: require("../assets/cover-massageseats.png"),
        video: require("../assets/massageseats.mp4"),
        title: "Massage and Heat Seats",
        text: "Switch on the Perfect Position seats with massage, heat, and ventilation** during your moment of sanctuary.",
      },
      {
        itemNumber: 4,
        tags: ["navigator"],
        cover: require("../assets/cover-activeglide-navigator.png"),
        video: require("../assets/navigator-activeglide.mp4"),
        title: "Hands-free Highway Driving",
        text: "Operate hands-free highway driving in your Lincoln Navigator® SUV while keeping your eyes on the road with ActiveGlide™ - Lincoln driver-assist technology.",
      },
    ],
  },
  {
    img: require("../assets/lake.png"),
    mainTitle: "Moment of Sanctuary",
    secondaryTitle: "Drive to a scenic spot for a moment of meditation.",
    section: "Moments",
    momentSectionItems: [
      {
        itemNumber: 1,
        img: require("../assets/garrett.png"),
        mapImg: require("../assets/map1.png"),
        title: "Garret Mountain Reservation",
        text: "Garret Mountain Reservation is a 568-acre park located on First Watchung Mountain in Paterson and Woodland Park in southern Passaic County, New Jersey.",
        address: "387 Rifle Camp Rd, Woodland Park, NJ 07424",
        lat: 40.900556,
        long: -74.177778,
      },
      {
        itemNumber: 2,
        img: require("../assets/franklin.png"),
        mapImg: require("../assets/map2.png"),
        title: "Franklin Lakes Nature Preserve",
        text: "Franklin Lakes Nature Preserve is a 147 acre public nature reserve located mostly within Franklin Lakes, New Jersey, with a tiny portion extending into North Haledon, New Jersey",
        address: "387 Rifle Camp Rd, Woodland Park, NJ 07424",
        lat: 40.980833,
        long: -74.197778,
      },
      {
        itemNumber: 3,
        img: require("../assets/bramhall.png"),
        mapImg: require("../assets/map3.png"),
        title: "Bramhall Terrace at South Mountain Reservation",
        text: "Historical landmark in Maplewood, New Jersey",
        address: "387 Rifle Camp Rd, Woodland Park, NJ 07424",
        lat: 40.75,
        long: -74.292778,
      },
      {
        itemNumber: 4,
        img: require("../assets/rifle.png"),
        mapImg: require("../assets/map4.png"),
        title: "Rifle Camp Park",
        text: "Rifle Camp Park is a 225-acre county park of Passaic County, New Jersey. It is located mostly within Woodland Park, but its eastern edge extends into Clifton as well",
        address: "387 Rifle Camp Rd, Woodland Park, NJ 07424",
        lat: 40.884444,
        long: -74.182222,
      },
    ],
  },
  {
    img: require("../assets/med.png"),
    detailImage: require("../assets/meditationDetail.jpg"),
    mainTitle: "Meditation",
    secondaryTitle:
      "Immerse yourself in Calm's minfulness content with the Revel Audio System.",
    section: "Meditation",
    title: "Try a Calm®† Meditation",
    text: `Find a picturesque place to park and switch on the available Perfect Position seats with massage, heat and ventilation and immerse yourself in the available Revel® Audio System soundscape.`,
    text_2: "Lincoln offers a complimentary 12-month subscription to Calm with a vehicle purchase.",
  },
  {
    img: require("../assets/reward.png"),
    mainTitle: "Your Reward",
    secondaryTitle:
      "Enjoy your chosen experience and preview Lincoln Access Rewards.",
    section: "Rewards",
    rewardSectionItems: [
      {
        page: 1,
        rewardId: "Capital",
        locationName: "The Capital Grille",
        locationAddress: "1 Garden State Plaza, Paramus, NJ 07652",
        googleLocationAddress: "1 Garden State Plaza, Paramus, NJ 07652",
        title: "Culinary — Dinner for Two",
        text: "Nationally acclaimed dry-aged steaks, fresh seafood and an award-winning collection of 350+ wines.",
        indexImage: require("../assets/capital.png"),
        rewardImage: require("../assets/capitalDetail.png"),
        lat: 40.917519,
        long: -74.076721,
      },
      {
        page: 2,
        rewardId: "Red",
        locationName: "Red Inside",
        locationAddress: "109 MacDougal St., Suite 2, New York, NY 10012",
        googleLocationAddress: "109 MacDougal St., Suite 2, New York, NY 10012",
        title: "Culinary — Cooking Lessons",
        text: "In person cooking lessons for two. Whether you are an amateur cook, food enthusiast or a full-blown smallholder, there’s a course to inspire you.",
        indexImage: require("../assets/red.png"),
        rewardImage: require("../assets/redDetail.png"),
        lat: 40.72993,
        long: -74.00082,
      },
      {
        page: 3,
        rewardId: "Aire",
        locationName: "AIRE Ancient Baths",
        googleLocationAddress: "88 Franklin St., New York, NY 10013",
        locationAddress: "88 Franklin St., New York, NY 10013",
        title: "Wellness — Spa Relaxation",
        text: "A 45-minute, full-body, relaxing massage plus a journey of relaxation in a candlelit historical building, touring different baths at varying temperatures (for one)",
        indexImage: require("../assets/aire.png"),
        rewardImage: require("../assets/aireDetail.png"),
        lat: 40.71817,
        long: -74.00476,
      },
      {
        page: 4,
        rewardId: "Hunter",
        locationName: "Hunter Mountain",
        googleLocationAddress: "64 Klein Ave., Hunter, NY 12442",
        locationAddress: "64 Klein Ave., Hunter, NY 12442",
        title: "Travel — Ski Pass Adventure",
        intro: "Head outdoors to enjoy a ski pass for two at  Hunter Mountain.",
        text: "Hunter Mountain is a destination that is so close to home but will make you feel like you are worlds away. Escape your daily routine and experience a winter adventure.",
        indexImage: require("../assets/hunter.png"),
        rewardImage: require("../assets/hunterDetail.png"),
        lat: 42.204659,
        long: -74.211128,
      },
      {
        page: 5,
        rewardId: "Nr",
        title:
          "You opted not to select a reward experience as part of your test drive.",
        text: "Through the Lincoln Access Rewards program owners have access to a wide range of experiences and services including vehicle care, culinary opportunities and many more.",
        indexImage: require("../assets/capital.png"),
        rewardImage: require("../assets/capitalDetail.png"),
      },
    ],
  },
  {
    img: require("../assets/ownership.png"),
    detailImage: require("../assets/ownershipDetail-1.png"),
    mainTitle: "Effortless Ownership",
    secondaryTitle: "Explore more Lincoln Access Reward benefits.",
    section: "Ownership",
    detailText:
      "Enjoy a range of services that give you back time and provide peace of mind.",
    ownershipItems: [
      {
        itemNumber: 1,
        icon: require("../assets/pickup-icon.png"),
        title: "Lincoln Pickup and Delivery*",
        text: "We will pick up your vehicle when it needs service and return it to you when ready, while providing you with alternate transportation options.",
      },
      {
        itemNumber: 2,
        icon: require("../assets/service-icon.png"),
        title: "Lincoln Mobile Service**",
        text: "Receive routine maintenance on your vehicle without leaving your home or office — our technicians will come straight to you.",
      },
      {
        itemNumber: 3,
        icon: require("../assets/roadside-icon.png"),
        title: "24-Hour Roadside Assistance",
        text: "Receive complimentary 24/7 Roadside Assistance you can request through the Lincoln Way App†† or simply by contacting a Lincoln Concierge.",
      },
      {
        itemNumber: 4,
        icon: require("../assets/concierge-icon.png"),
        title: "Lincoln Concierge†",
        text: "Enjoy personalized assistance from a dedicated team of Lincoln experts, by connecting with a Lincoln Concierge via chat or phone.",
      },
    ],
  },
];

export const disclaimers = {
  "Effortless Ownership": `*Complimentary Lincoln Pickup & Delivery service is available for 2017 model-year and newer Lincoln vehicles within the 4-year/50,000-mile New Vehicle Limited Warranty. Owners of Lincoln vehicles outside of the 4-year/50,000-mile New Vehicle Limited Warranty may arrange for the service at a cost. Mileage limitations may apply.

**Lincoln Mobile Service is offered by participating retailers and may be limited based on availability, distance or other retailer-specified criteria. Does not include parts or repair charges.

†Coverage for original owner varies based on vehicle model-year. Nontransferable. For complete details go to www.lincoln.com/support or see your Lincoln Retailer.

††Lincoln Way App, compatible with select smartphone platforms, is available via a download. Message and data rates may apply.`,
  "Navigator Features": `*Available feature on 2023 Lincoln Navigator vehicles. Includes a two-year connected service plan with regular map updates after which purchase is required. Requires Lincoln Way® App and modem activation. Driver-assist features are
supplemental and do not replace the driver's attention, judgment and need to control the vehicle. Lincoln ActiveGlide™ is a hands-free highway driving feature on Lincoln Navigator vehicles. Only remove hands from the steering wheel when in a Hands-Free Blue Zone. Always watch the road and be prepared to resume control of the vehicle. It does not replace safe driving. See Owner’s Manual for details and limitations. 2023 Lincoin Navigator Standard includes a 90-day trial with the
option to purchase a two-year connected service plan with regular map updates. Requires Lincoln Way App and modem activation.

**Feature only available on 2023 Lincoln Navigator SUV.`,
  "Aviator Features": `*Driver-assist features are supplemental and do not replace the driver's attention, judgment and need to control the vehicle. It does not replace safe driving. See Owner's Manual for details and limitations.

**If the stop is longer than 3 seconds driver must tap accelerator pedal or push resume button to confirm that he or she wants the vehicle to start moving again.

†Feature only available on 2023 Lincoln Aviator SUV.`,
  "Corsair Features": `*Driver-assist features are supplemental and do not replace the driver's attention, judgment and need to control the vehicle. It does not replace safe driving. See Owner's Manual for details and limitations.

**If the stop is longer than 3 seconds driver must tap accelerator pedal or push resume button to confirm that he or she wants the vehicle to start moving again`,
  "Nautilus Features": `*Driver-assist features are supplemental and do not replace the driver's attention, judgment and need to control the vehicle. It does not replace safe driving. See Owner's Manual for details and limitations.

**If the stop is longer than 3 seconds driver must tap accelerator pedal or push resume button to confirm that he or she wants the vehicle to start moving again.

†Feature only available on 2023 Lincoln Nautilus SUV.`,
  "Calm Meditation": `†Lincoln will offer a complimentary 12-month subscription to Calm through the Lincoln Way® App for current owners of a 2021 model-year or newer vehicle with a registered vehicle identification number (VIN) in a Lincoln Way account. Subscription period begins from the date of activation via the Lincoln Way App. Promotion ends 12/31/23. Don’t drive while distracted or while using handheld devices. Use voice-operated systems when possible. Program may be modified, suspended or terminated at any time by Lincoln.`,
  "Lincoln Access Rewards": `*Must be enrolled in Lincoln Access Rewards. Modem must be activated within 60 days of purchase of 2020 or newer model-year Lincoln vehicles through the Lincoln Way® App on a smartphone and remain active for at least 180 days after activation. Visit a participating retailer today for details.`,
};
