import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, isPlatform } from '@ionic/react';
import { Link } from 'react-router-dom';
import './Moment.css';

interface ContainerProps {
  item: any;
}

const goToLocation = (item: any) => {
  const encodedAddress = encodeURI(`${item.title} ${item.address}`);
    if(isPlatform("ios")) {
      const url = `https://maps.apple.com/?daddr=${encodedAddress}`;
      window.open(url, '_system');
    } else {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
      window.open(url, '_system');
    }
  }

const Moment: React.FC<ContainerProps> = ({ item }) => {
  return (
    <IonCard>
      <img src={item.img} />
      <img src={item.mapImg} className="map-image" />
      <IonCardHeader>
        <IonCardTitle>{item.title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <p>{item.text}</p>
        <Link to="#" onClick={() => goToLocation(item)}><strong>Address:</strong> {item.address}</Link>
        {/* <IonButton className='moment-address-link' shape="round" fill="outline" color="dark" onClick={() => goToLocation(item)}>View Map</IonButton> */}
      </IonCardContent>
    </IonCard>
  );
};

export default Moment;
