import { useHistory } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Cards.css";

import { EffectCoverflow, Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";
import { IonPage } from "@ionic/react";
import { appData } from '../data/AppData';

import lincolnLogo from '../assets/lincoln.png';

export default function Cards() {
  const history = useHistory();

  return (
    <IonPage>
      <div className="swiper-container">
        <img src={lincolnLogo} className="lincoln-logo" width="50px" height="35px" />
        <small className="welcome-text">Try our suggested inspirational test-drive itinerary as you glide in your Lincoln vehicle.</small>
        <Swiper
          effect={"coverflow"}
          loop={false}
          centeredSlides={true}
          initialSlide={0}
          slidesPerView={2}
          coverflowEffect={{
            rotate: 10,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={false}
          navigation={false}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="mySwiper"
        >
          {appData?.filter(item => !item.section.includes("Access")).map(item =>
            <SwiperSlide key={item.section} onClick={() => history.push(item.section.toLocaleLowerCase())}>
              <img src={item.img} />
              <h5 className='slide-title'>{item.mainTitle}</h5>
              <p className='secondary-title'>{item.secondaryTitle}</p>
              <Link to={item.section} className="learn-more">Learn More</Link>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </IonPage>
  );
}
