import { IonPage, IonContent, IonGrid, IonRow } from "@ionic/react";
import "./Disclaimers.css";
import { disclaimers } from "../data/AppData";
import CloseButton from "../components/CloseButton";
import { useHistory } from "react-router-dom";

const Disclaimers = () => {
  let history = useHistory();
  return (
    <IonPage style={{ whiteSpace: "pre-line" }}>
      <IonContent fullscreen>
        <div className='sticky-header'>
          <CloseButton className="close-button" myHistory={history} />
        </div>
        <IonGrid>
          <IonRow>
            <p className="disclaimer-title">Disclaimers</p>
            {Object.entries(disclaimers).map(([key, value]) => (
              <div key={key}>
                <p className="disclaimer-header">{key}</p>
                <p className="disclaimer-text">{value}</p>
              </div>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Disclaimers;
