import { IonButton, IonContent, IonHeader, IonItem, IonIcon, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { close } from 'ionicons/icons';
import "./CloseButton.css";


const CloseButton: React.FC<any>= ({myHistory}) => {
  return (
    <IonButton className="close-button" shape="round" fill="outline" color="light" onClick={(event) => {
      event.preventDefault();
      myHistory.push('/cards');
    }}><IonIcon slot="start" icon={close} />Close</IonButton>
  );
};

export default CloseButton;
