import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router-dom';
const Index: React.FC = (props) => {
  const params = useParams();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>index</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Index</IonTitle>
            <IonItem routerLink="/ownership">
              <IonLabel>Ownership</IonLabel>
            </IonItem>
            <IonItem routerLink="/features">
              <IonLabel>Features</IonLabel>
            </IonItem>
            <IonItem routerLink="/moments">
              <IonLabel>Moments</IonLabel>
            </IonItem>
            <IonItem routerLink="/meditation">
              <IonLabel>Meditation</IonLabel>
            </IonItem>
            <IonItem routerLink="/rewards">
              <IonLabel>Rewards</IonLabel>
            </IonItem>
            <IonItem routerLink="/cards">
              <IonLabel>Cards</IonLabel>
            </IonItem>
          </IonToolbar>
        </IonHeader>
      </IonContent>
    </IonPage>
  );
};

export default Index;
