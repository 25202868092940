import { IonCard } from '@ionic/react';
import './AccessDetail.css';

interface ContainerProps {
  item: any;
}

const AccessDetail: React.FC<ContainerProps> = ({ item }) => {
  const subItems = item.subItems;

  return (
    <div className='access-detail'>
        <h4>{item.mainTitle}</h4>
        <p>{item.text}</p>
      {subItems.map((subItem: any) =>
        <IonCard key={subItem.itemNumber} className="ion-card-access">
          <img className='sub-image' src={subItem.image} />
          <h6>{subItem.title}</h6>
          <p className='access-detail-text'>{subItem.text}</p>
        </IonCard>
      )}
    </div>
  );
};

export default AccessDetail;
