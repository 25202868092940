import { useHistory } from 'react-router';
import { IonButton, IonCard } from '@ionic/react';
import './AccessReward.css';

const Feature: React.FC = () => {
  const history = useHistory();
  return (
    <IonCard className='access-rewards-section'>
      <h5>Lincoln Access Rewards™</h5>
      <p>Unlock more benefits the more you engage with Lincoln. You will be able to redeem points for entertainment gear, health and wellness equipment, travel experiences and more.</p>
      <IonButton shape="round" color="light" onClick={() => history.push("access")}>Learn More</IonButton>
    </IonCard>
  );
};

export default Feature;
