import { IonButton, IonContent, IonHeader, IonItem, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { appData } from '../data/AppData';
import { useHistory } from 'react-router';
import Moment from '../components/Moment';
import "./Moments.css";
import CloseButton from '../components/CloseButton';

const Moments: React.FC = () => {
  const moments = appData.find(item => (item.section == "Moments"));
  let history = useHistory();
  return (
    <IonPage className="moments">
      <IonContent fullscreen>
        <div className='sticky-header'>
          <CloseButton className="close-button" myHistory={history} />
        </div>
        {moments?.momentSectionItems?.map(item =>
          <Moment key={item.itemNumber} item={item} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Moments;
