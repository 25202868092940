import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Welcome from "./pages/Welcome";
import Index from "./pages/Index";
import Features from "./pages/Features";
import Access from "./pages/Access";
import Moments from "./pages/Moments";
import Meditation from "./pages/Meditation";
import Rewards from "./pages/Rewards";
import Ownership from "./pages/Ownership";
import Cards from "./pages/Cards";
import Settings from "./pages/Settings";
import Disclaimers from "./pages/Disclaimers";
import Health from "./pages/Health";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./App.css";

setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet animated={false}>
          <Route exact path="/welcome">
            <Welcome />
          </Route>
          <Route path="/welcome/:vehicle/:reward">
            <Welcome />
          </Route>
          <Route path="/welcome/:vehicle/:reward/:date/:time">
            <Welcome />
          </Route>
          <Route exact path="/index">
            <Index />
          </Route>
          <Route exact path="/features">
            <Features />
          </Route>
          <Route exact path="/moments">
            <Moments />
          </Route>
          <Route exact path="/meditation">
            <Meditation />
          </Route>
          <Route exact path="/rewards">
            <Rewards />
          </Route>
          <Route exact path="/ownership">
            <Ownership />
          </Route>
          <Route path="/cards">
            <Cards />
          </Route>
          <Route exact path="/access">
            <Access />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route exact path="/">
            <Redirect to="/welcome" />
          </Route>
          <Route path="/disclaimers">
            <Disclaimers />
          </Route>
          <Route path="/health">
            <Health />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
