import { IonContent, IonDatetime, IonDatetimeButton,  IonItem, IonList, IonModal, IonPage, IonSelect, IonSelectOption } from '@ionic/react';
import "./Settings.css";

const Settings: React.FC = () => {

  const tryThis = (event: any) => {
    console.log("THIS", event.target.id);
    console.log("THIS", event.target.value);
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonList>
          <IonItem>
            <IonSelect id='vehicle' placeholder="Select Vehicle" onIonChange={tryThis}>
              <IonSelectOption value="navigator">Navigator</IonSelectOption>
              <IonSelectOption value="aviator">Aviator</IonSelectOption>
              <IonSelectOption value="nautilus">Nautilus</IonSelectOption>
              <IonSelectOption value="corsair">Corsair</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonSelect id='reward' placeholder="Select Reward" onIonChange={tryThis}>
              <IonSelectOption value="Red">Red</IonSelectOption>
              <IonSelectOption value="Capital">Capital</IonSelectOption>
              <IonSelectOption value="Aire">Aire</IonSelectOption>
              <IonSelectOption value="Hunter">Hunter</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
              <IonDatetime id="datetime" onChange={tryThis}></IonDatetime>
            </IonModal>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
