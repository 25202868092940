import { IonCard } from '@ionic/react';
import './Feature.css';

interface ContainerProps {
  item: any;
}

const Feature:React.FC<ContainerProps> = ({ item }) => {
    return (
        <IonCard>
            <h5>{item.title}</h5>
            <p className='features-text'>{item.text}</p>
            <video poster={item.cover} className='feature-video' controls>
                <source src={item.video} type="video/mp4" />
            </video>
        </IonCard>
    );
};

export default Feature;
