import { IonContent, IonPage } from "@ionic/react";
import { useHistory, useParams } from "react-router";
import Cookies from "universal-cookie";
import "./Welcome.css";

import welcome from "../assets/welcome.png";
import lincolnLogoWelcome from "../assets/lincoln-logo-welcome.png";

const capitalize = (s: string) => {
  return s[0].toUpperCase() + s.slice(1);
};

const formatDate = (date: string, time: string) => {
  try {
    const newDate = date.replace(/(\d{2})(\d{2})(\d{4})/, "$3-$1-$2");
    const newTime = time.replace(/(\d{2})(\d{2})/, "$1:$2");

    const dateTimeString = `${newDate} ${newTime}`;
    return dateTimeString;
  } catch {}
};

const Welcome: React.FC = () => {
  const history = useHistory();
  const params = useParams();
  const cookies = new Cookies();

  const parseParams = (params: any) => {
    const vehicle = params.vehicle;
    const reward = capitalize(params.reward);
    const rewardDateString = formatDate(params.date, params.time);

    cookies.set("vehicle", vehicle, { path: "/" });
    cookies.set("reward", reward, { path: "/" });
    cookies.set("rewardDateString", rewardDateString, { path: "/" });
  };
  parseParams(params);

  return (
    <IonPage>
      <IonContent fullscreen onTouchEnd={() => history.replace("/cards")}>
        <img src={welcome} className="welcome" />
        <div className="welcome-overlay">
          <img src={lincolnLogoWelcome} className="lincoln-logo-welcome" />
          <p className="welcome-header">Welcome</p>
          <h3 className="welcome-main-text">
            Experience the Lincoln Lifestyle
          </h3>
          <p className="welcome-tap">Tap to Begin</p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
